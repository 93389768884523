import * as React from "react";
import DefautLayout from "../layouts/default";

const IndexPage = () => {
  return (
    <DefautLayout title="Impressum">
      <div className="p-standard content">
        <section id="header">
          <h1 className="py-20">Impressum</h1>
        </section>
        <h2 className="pt-50 pb-20">Herausgeber</h2>
        <p>
          Bayerisches Staatsministerium für Digitales
          <br />
          Oskar-von-Miller-Ring 35
          <br />
          80333 München
          <br />
          Telefon: 089 / 453549-0
          <br />
          Telefax: 089 / 453549-242
          <br />
          E-Mail:
          <a href="mailto:poststelle@stmd.bayern.de">
            poststelle@stmd.bayern.de
          </a>
          <br />
          Vertretungsberechtigter: Ministerialdirektor Dr. Hans Michael Strepp
          <br />
          USt-Identifikationsnummer (gemäß § 27 a Umsatzsteuergesetz):
          DE811335517
          <br />
        </p>
      
        <h2 className="pt-50 pb-20">Verantwortlich für den Inhalt</h2>
        <p>
          UnternehmerTUM GmbH
          <br />
          Lichtenbergstr. 6<br />
          85748 Garching b. München
        </p>
        <h3 className="py-10">Vertreten durch</h3>
        <p>
          Prof. Dr. Helmut Schönenberger (CEO)
          <br />
          Claudia Frey
          <br />
          Stefan Drüssler
          <br />
          Christian Mohr
          <br />
          Thomas Zeller
          <br />
        </p>
        <h3 className="py-10">Kontakt</h3>
        <p>
          +49 89-18 94 69-0
          <br />
          <a href="mailto:nextgen@unternehmertum.de">
          nextgen@unternehmertum.de
          </a>
        </p>
        <h3 className="py-10">Registereintrag</h3>
        <p>
          Eintragung im Handelsregister.
          <br />
          Registergericht: Amtsgericht München
          <br />
          Registernummer: HRB 141703
          <br />
        </p>
        <h3 className="py-10">Umsatzsteuer</h3>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE
          252 789 694 <br />
          Verantwortlich im Sinne von § 18 Abs. 2 MStV
          <br />
          Prof. Dr. Helmut Schönenberger (CEO)
          <br />
          Claudia Frey
          <br />
          Stefan Drüssler
          <br />
          Christian Mohr
          <br />
          Thomas Zeller
          <br />
        </p>

        <h2 className="pt-50 pb-20">Nutzungsbedingungen</h2>
        <p>
          Texte, Bilder, Grafiken sowie die Gestaltung dieser Internetseiten
          unterliegen dem Urheberrecht. Nicht urheberrechtlich geschützt sind
          nach § 5 des Urheberrechtsgesetz (UrhG)
          Gesetze, Verordnungen, amtliche Erlasse und Bekanntmachungen sowie
          Entscheidungen und amtlich verfasste Leitsätze zu Entscheidungen und
          andere amtliche Werke, die im amtlichen Interesse zur allgemeinen
          Kenntnisnahme veröffentlicht worden sind, mit der Einschränkung,
          dass die Bestimmungen über Änderungsverbot und Quellenangabe in § 62
          Abs. 1 bis 3 und § 63 Abs. 1 und 2 UrhG entsprechend anzuwenden
          sind.
        </p>
        <p>
          Sie dürfen von Ihnen nur zum privaten und sonstigen eigenen Gebrauch
          im Rahmen des § 53 Urheberrechtsgesetz (UrhG) verwendet werden. Eine
          Vervielfältigung oder Verwendung dieser Seiten oder Teilen davon in
          anderen elektronischen oder gedruckten Publikationen und deren
          Veröffentlichung ist nur mit unserer Einwilligung gestattet. Diese
          erteilen auf Anfrage die für den Inhalt Verantwortlichen. Weiterhin
          können Texte, Bilder, Grafiken und sonstige Dateien ganz oder
          teilweise dem Urheberrecht Dritter unterliegen. Auch über das Bestehen
          möglicher Rechte Dritter geben Ihnen die für den Inhalt
          Verantwortlichen nähere Auskünfte. Der Nachdruck und die Auswertung
          von Pressemitteilungen und Reden sind mit Quellenangabe allgemein
          gestattet.
        </p>

        <h2 className="pt-50 pb-20">Bildnachweis</h2>
        <p>
          Die Bildrechte für die auf dieser Webseite verwendeten Bilder sind
          geklärt. Quellenangaben erfolgen in der Bildunterschrift oder im
          Alternativtext zum Bild. Bilder ohne explizite Quellenangaben sind
          grundsätzlich mit Quelle „Digital Product School by UnternehmerTUM” zu
          führen. Ihre Nutzung bedarf der Zustimmung der UnternehmerTUM GmbH.
          Sollten Sie sich, trotz aller Sorgfalt unserer Arbeit, in einem
          Bildrecht verletzt fühlen, wenden Sie sich bitte an:
          <a href="mailto:nextgen@unternehmertum.de">
          nextgen@unternehmertum.de
          </a>
        </p>

        <h2 className="pt-50 pb-20">Haftungsausschluss</h2>
        <p>
          Alle auf dieser Internetseite bereitgestellten Informationen haben wir
          nach bestem Wissen und Gewissen erarbeitet und geprüft. Eine Gewähr
          für die jederzeitige Aktualität, Richtigkeit, Vollständigkeit und
          Verfügbarkeit der bereit gestellten Informationen können wir
          allerdings nicht übernehmen. Ein Vertragsverhältnis mit den Nutzern
          des Internetangebots kommt nicht zustande. Wir haften nicht für
          Schäden, die durch die Nutzung dieses Internetangebots entstehen.
          Dieser Haftungsausschluss gilt nicht, soweit die Vorschriften des §
          839 BGB (Haftung bei Amtspflichtverletzung) einschlägig sind. Für
          etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch
          Schadsoftware oder der Installation oder Nutzung von Software
          verursacht werden, übernehmen wir keine Haftung.
        </p>

        <h2 className="pt-50 pb-20">Links</h2>
        <p>
          Von unseren eigenen Inhalten sind Querverweise („Links“) auf die
          Webseiten anderer Anbieter zu unterscheiden. Durch diese Links
          ermöglichen wir lediglich den Zugang zur Nutzung fremder Inhalte nach
          § 8 Telemediengesetz. Bei der erstmaligen Verknüpfung mit diesen
          Internetangeboten haben wir diese fremden Inhalte daraufhin überprüft,
          ob durch sie eine mögliche zivilrechtliche oder strafrechtliche
          Verantwortlichkeit ausgelöst wird. Wir können diese fremden Inhalte
          aber nicht ständig auf Veränderungen überprüfen und daher auch keine
          Verantwortung dafür übernehmen. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung von Informationen Dritter entstehen, haftet
          allein der jeweilige Anbieter der Seite.
        </p>
      </div>
    </DefautLayout>
  );
};

export default IndexPage;
